import { SEGMENT_WRITE_KEY } from 'src/env';
import fetch from 'isomorphic-unfetch';
import utils from 'public/assets/js/analytics/utilities';
import parsePath from 'src/lib/parse-path';
import { fetchDatafile, initialize } from 'src/lib/optimizely';

export const normalizeContentSettings = (entity = '') => {
  // cookie categories:
  // C0001: strictly necessary
  // C0002: performance
  // C0003: functional
  // C0004: targeting
  const consentCategories = new Set(['C0001', 'C0002', 'C0003', 'C0004']);
  return entity.split(',').filter((category) => {
    return consentCategories.has(category);
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchDestinationsFromSegment = async () => {
  try {
    const res = await fetch(
      `https://cdn.segment.com/v1/projects/${SEGMENT_WRITE_KEY}/integrations`,
    );

    const destinations = await res.json();

    // Rename creationName to id to abstract the weird data model
    for (const destination of destinations) {
      destination.id = destination.creationName;
      delete destination.creationName;
    }
    return destinations;
  } catch (error) {
    throw new Error(`Failed to fetch integrations`, { cause: error });
  }
};

export const getConsentMiddleware = (
  destinationPreferences,
  categoryPreferences,
  defaultDestinationBehavior,
) => {
  return ({ payload, next }) => {
    payload.obj.context.consent = {
      categoryPreferences,
      destinationPreferences,
      defaultDestinationBehavior,
    };
    next(payload);
  };
};

const pushDataLayerSignupRequestSuccess = () => {
  if (!window) return;

  const uuid = window.generateUUID();
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'LI-CAPI-Signup-Request-Success',
    user_data: {
      // eslint-disable-line
      linkedinFirstPartyId: window.li_fat_id,
    },
    currency: 'USD',
    value: 0,
    event_id: uuid, // eslint-disable-line
  });
};

// load analytics on each page reload only if any preferences are chosen
export const conditionallyInitializeAnalytics = (activeGroups) => {
  if (window?.analytics && !window.analytics.initialized) {
    if (activeGroups.length > 1) {
      fetchDestinationsFromSegment().then((destinations) => {
        // the integrations object manages the data flow to Segment destinations. We set the integrations object based
        // on the user preferences when the page reloads and send it with the analytics.load() call.
        const integrations = utils.getIntegrations(destinations, activeGroups);
        // gets tracking preferences (which includes third parties) for segment middleware. The middleware passes additional
        // context in segment tracks and page views
        const destinationPreferences = utils.getAllPreferences(
          destinations,
          activeGroups,
        );
        const categoryPreferences = utils.getCategoryPreferences(activeGroups);
        // we send this user preferences data to the
        // context field when it loads analytics to send additional data for segment tracks and page views.
        const defaultDestinationBehavior = 'imply';
        const middleware = getConsentMiddleware(
          destinationPreferences,
          categoryPreferences,
          defaultDestinationBehavior,
        );
        window?.analytics.addSourceMiddleware(middleware);
        window?.analytics.load(SEGMENT_WRITE_KEY, {
          integrations: integrations,
        });

        // Data Layer
        pushDataLayerSignupRequestSuccess();
      });
    }
  }
};

// load optimizely on each page reload only if performance catetory is chosen
export const conditionallyLoadOptimizely = async (activeGroups) => {
  if (activeGroups.includes('C0002')) {
    // C0002 is the performance category
    const datafile = await fetchDatafile(process.env.OPTIMIZELY_DATAFILE_URL);
    if (datafile && typeof window !== 'undefined') {
      window.optimizelyClientInstance = initialize(datafile);
    }
  }
};

// on preference changes, this mimics the old Segment consent manager. which set these traits so we can access them on the
// server-side and from destinations (warehouse) https://github.com/segmentio/consent-manager#segment-consent-manager
export const setUserTraits = (activeGroups = []) => {
  if (activeGroups.length > 1) {
    fetchDestinationsFromSegment().then((destinations) => {
      const destinationPreferences = utils.getAllPreferences(
        destinations,
        activeGroups,
      ); // includes third parties
      window?.analytics.identify({
        destinationTrackingPreferences: destinationPreferences,
      });
    });
  }
};

export const hideDocsCookieButton = (route) => {
  const floatingCookie: HTMLElement = document?.querySelector(
    '#ot-sdk-btn-floating',
  );
  if (floatingCookie) {
    const path = parsePath(route).pathname;
    floatingCookie.style.cssText =
      path.slice(0, 5) === '/docs'
        ? 'display:none !important'
        : floatingCookie.classList.contains('ot-hide')
        ? 'display:none !important'
        : 'display:inline !important';
  }
};
