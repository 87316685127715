import React from 'react';
import ThirdPartyScripts from './ThirdPartyScripts';
import useOneTrust from 'src/containers/ConsentManager/useOneTrust';
import Script from 'next/script';
import { PreviewContext } from 'src/contexts';
import { isProduction } from 'src/lib/constants';

const ConsentManager: React.FC = () => {
  const { consentSettings } = useOneTrust();
  const previewContext = React.useContext(PreviewContext);

  // we hide the cookie banner for previews
  if (previewContext?.preview) {
    return null;
  }

  return (
    <>
      {isProduction && (
        <Script
          src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
          type='text/javascript'
          data-domain-script='33c336c7-85e9-4246-9792-798feeeb4ed8'
          strategy='beforeInteractive'
        />
      )}
      {!isProduction && (
        <Script
          src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
          type='text/javascript'
          data-domain-script='33c336c7-85e9-4246-9792-798feeeb4ed8-test'
          strategy='beforeInteractive'
        />
      )}
      {/*
          we load this mutiny script outside of the ThirdPartyScripts.tsx so
          it will not be loaded asynchronously. It is categorized as strictly
          necessary as it is a dependency of Mutiny and not actually used for
          personalizing the website until a user has consented via scripts loaded in Third
          PartyScrips.tsx and Segment https://plaidinc.atlassian.net/browse/SITE-4954
      */}
      <Script
        strategy='beforeInteractive'
        id='mutiny-client-script'
        dangerouslySetInnerHTML={{
          __html: `
                (function(){var a=window.mutiny=window.mutiny||{};if(!window.mutiny.client){a.client={_queue:{}};var b=["identify","trackConversion"];var c=[].concat(b,["defaultOptOut","optOut","optIn"]);var d=function factory(c){return function(){for(var d=arguments.length,e=new Array(d),f=0;f<d;f++){e[f]=arguments[f]}a.client._queue[c]=a.client._queue[c]||[];if(b.includes(c)){return new Promise(function(b,d){a.client._queue[c].push({args:e,resolve:b,reject:d});setTimeout(d,500)})}else{a.client._queue[c].push({args:e})}}};c.forEach(function(b){a.client[b]=d(b)})}})();
              `,
        }}
      />
      <ThirdPartyScripts consentSettings={consentSettings} />
      <Script
        id='linkedins-conversions-api'
        dangerouslySetInnerHTML={{
          __html: `function getCookie(name) {let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"));return matches ? decodeURIComponent(matches[1]) : undefined;}if (typeof li_fat_id === "undefined") {var li_fat_id = (typeof window !== "undefined" && new URLSearchParams(window.location.search).get("li_fat_id")) || (typeof document !== "undefined" && getCookie("li_fat_id")) || "";}function generateUUID() {return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {const r = (Math.random() * 16) | 0,v = c == "x" ? r : (r & 0x3) | 0x8;return v.toString(16);});};const uuid = generateUUID();window.dataLayer = window.dataLayer || [];window.dataLayer.push({event: 'LI-CAPI-Page-View',user_data: {linkedinFirstPartyId: li_fat_id,},currency: 'USD',value: 0,event_id: uuid});async function hashData(value){const encoder=new TextEncoder();const data=encoder.encode(value.toLowerCase());const hashBuffer=await crypto.subtle.digest('SHA-256',data);const hashArray=Array.from(new Uint8Array(hashBuffer));const hashHex=hashArray.map(b=>b.toString(16).padStart(2,'0')).join('');return hashHex}`,
        }}
      />
    </>
  );
};

export default ConsentManager;
